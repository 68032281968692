@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600|Roboto&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
    line-height: 1;
    height: 100vh;
    overflow-y: hidden;
}
ol, ul {
	list-style: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
}

blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.btn:focus,.btn:active:focus,.btn.active:focus,
.btn.focus,.btn:active.focus,.btn.active.focus {
	outline: none;
    box-shadow:none;
}

.gray-color {
	color: #565f69 !important;
}

.gray-background {
    background-color: #565f69 !important;
}

.blue-color {
    color: #00a8db !important;
}

.blue-background {
    background-color: #00a8db !important;
}

.red-color {
    color: #ff585f !important;
}

.red-background {
    background-color: #ff585f !important;
}

.green-color {
    color: #00ce88 !important;
}

.green-background {
    background-color: #00ce88 !important;
}

.orange-color {
    color: #ff5722 !important;
}

.orange-background {
    background-color: #ff5722 !important;
}

.yellow-color {
    color: #ffc300 !important;
}

.yellow-background {
    background-color: #ffc300 !important;
}

.dark-blue-color {
    color: #007bff !important;
}

.dark-blue-background {
    background-color: #007bff !important;
}

.purple-color {
    color: #8e24aa !important;
}

.purple-background {
    background-color: #8e24aa !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

hr {
    border: 0;
    clear:both;
    display:block;
    background-color:#e6e6e6;
    height: 1px;
}

.switch {
	width: 40px;
	height: 25px;
	padding-left: 3px;

	input {
		display: none;
		visibility: hidden;

		&:checked + label:before {
			background-color: #b9b9b9;;
		}

		&:checked + label:after {
			background-color: #777777;
			-ms-transform: translate(80%, -50%);
			-webkit-transform: translate(80%, -50%);
			transform: translate(80%, -50%);
		}

		&:disabled + label:before {
			background-color: #e4e4e4;
		}
	
		&:disabled + label:after {
			background-color: rgb(202, 202, 202);
		}
	}

	label {
		position: relative;
		display: inline-block;
		cursor: pointer;
		text-align: left;

		&:before, &:after {
			content: "";
			position: absolute;
			margin: 0;
			outline: 0;
			top: 50%;
			-ms-transform: translate(0, -50%);
			-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%);
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}

		&:before {
			left: 1px;
			width: 34px;
			height: 14px;
			background-color: #9E9E9E;
			border-radius: 8px;
		}

		&:after {
			left: 0;
			width: 20px;
			height: 20px;
			background-color: #FAFAFA;
			border-radius: 50%;
			box-shadow: 0 3px 1px -2px #00000024, 0 2px 2px 0 #00000019, 0 1px 5px 0 #00000015;
		}
	}
}

/*
USAGE
<label class="switch-1">
	<input class="switch-input" type="checkbox" />
	<span class="switch-label" data-on="On" data-off="Off"></span> 
	<span class="switch-handle"></span> 
</label>
*/

.switch-2 {
	position: relative;
	display: block;
	width: 100px;
	height: 30px;
	padding: 3px;
	border-radius: 18px;
	cursor: pointer;
	box-sizing:content-box;

	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		box-sizing:content-box;
	}
	.switch-label {
		position: relative;
		display: block;
		height: inherit;
		font-size: 10px;
		text-transform: uppercase;
		background: #eceeef;
		border-radius: inherit;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
		box-sizing:content-box;
	}
	.switch-label:before, .switch-label:after {
		position: absolute;
		top: 50%;
		margin-top: -.5em;
		line-height: 1;
		-webkit-transition: inherit;
		-moz-transition: inherit;
		-o-transition: inherit;
		transition: inherit;
		box-sizing:content-box;
	}
	.switch-label:before {
		content: attr(data-off);
		right: 11px;
		color: #aaaaaa;
	}
	.switch-label:after {
		content: attr(data-on);
		left: 11px;
		color: #FFFFFF;
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label {
		background: #e6c233;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label:after {
		opacity: 1;
	}
	.switch-handle {
		position: absolute;
		top: 4px;
		left: 4px;
		width: 28px;
		height: 28px;
		background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
		background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
		border-radius: 100%;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-handle:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 12px;
		height: 12px;
		background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
		background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
		border-radius: 6px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
	}
	.switch-input:checked ~ .switch-handle {
		left: 74px;
		box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-label, .switch-handle {
		transition: All 0.3s ease;
		-webkit-transition: All 0.3s ease;
		-moz-transition: All 0.3s ease;
		-o-transition: All 0.3s ease;
	}
}
.switch-flat {
	padding: 0;
	background: #FFF;
	background-image: none;

	.switch-label {
		background: #FFF;
		border: solid 2px #eceeef;
		box-shadow: none;
	}
	.switch-label:after {
		color: #0088cc;
	}
	.switch-handle {
		top: 6px;
		left: 6px;
		background: #dadada;
		width: 22px;
		height: 22px;
		box-shadow: none;
	}
	.switch-handle:before {
		background: #eceeef;
	}
	.switch-input:checked ~ .switch-label {
		background: #FFF;
		border-color: #0088cc;
	}
	.switch-input:checked ~ .switch-handle {
		left: 72px;
		background: #0088cc;
		box-shadow: none;
	}
}
.switch-left-right {
	.switch-label {
		overflow: hidden;
	}
	.switch-label:before, .switch-label:after {
		width: 20px;
		height: 20px;
		top: 4px;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 11px 0 0 0;
		text-indent: -12px;
		border-radius: 20px;
		box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
	}
	.switch-label:before {
		background: #eceeef;
		text-align: left;
		padding-left: 80px;
	}
	.switch-label:after {
		text-align: left;
		text-indent: 9px;
		background: #FF7F50;
		left: -100px;
		opacity: 1;
		width: 100%;
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 1;
		left: 100px;
	}
	.switch-input:checked ~ .switch-label:after {
		left: 0;
	}
	.switch-input:checked ~ .switch-label {
		background: inherit;
	}
}

.ng-select {
	font-family: 'Raleway', sans-serif;

	&.ng-select-disabled>.ng-select-container {
		background-color: #e9ecef;
	}

	.ng-select-container {
		.ng-value-container {
			.ng-placeholder {
				font-size: 12px;
			}
		}
	}
	.ng-dropdown-panel {
		.ng-option {
			font-size: 12px !important;
		}
	}
	&.ng-select-multiple {
		.ng-select-container {
			.ng-value-container {
				.ng-placeholder {
					font-size: 12px;
					top: 50% !important;
					transform: translate(0, -50%) !important;
					padding: 0 0 0 3px !important;
				}
				.ng-value {
					.ng-value-icon {
						padding: 4px 8px !important;
					}
				}
			}
		}
	}
	&.ng-select-single {
		.ng-select-container {
			.ng-value-container {
				.ng-value {
					font-size: 12px;
				}
			}
		}
	}
}

.datepicker-today {
	background-color: rgba(189, 221, 230, 0.71);
	
	&:hover {
		background-color:rgba(189, 221, 230, 0.71) !important;
	}
}

.datepicker-form-group {
	position: relative;

	input:disabled+.datepicker-clear {
		pointer-events: none;
	}

	.datepicker-clear {
		pointer-events: all;
		cursor: pointer;
		height: calc(1.5em + 0.75rem + 2px);
		display: flex;
		align-items: center;
		position: absolute;
		right: 0.75rem;
		bottom: 0;

		i {
			font-size: 12px;
			font-weight: 600;
		}
	}
}

form {
    .custom-checkbox {
        min-height: calc(2.25rem + 2px);

        &.custom-checkbox-sm {
            min-height: 1.5rem;
        }

        .custom-control-label {
            margin-top: 2px;

            &::before {
                border: 1px solid #ced4da;
                background-color: white;
                top: 0;
            }
            &::after {
                top: 0;
            }
        }
    }
}

.custom-checkbox {
    &.centered-checkbox {
        .custom-control-label {
            position: absolute;
            height: 1rem;
            line-height: 1rem;
            font-size: 13px !important;
            top: 50%;
            transform: translate(0, -50%);
    
            &::before {
                top: 0;
            }
            &::after {
                top: 0;
            }
        }
    }
}

/*!
* Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
* Copyright 2015 Daniel Cardoso <@DanielCardoso>
* Licensed under MIT
*/
.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 999;
    visibility: visible;
    opacity: 1;
    background-color: #e4e4e680;
    transition: all 0.3s ease-in-out;
    
    &.fadeOut {
        opacity: 0;
        visibility: hidden;
    }

    .spinner {
        width: 40px;
        height: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        background-color: #333;
        border-radius: 100%;
        -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
        animation: sk-scaleout 1.0s infinite ease-in-out;
    }
}

@-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
}

.card {
	border: none !important;
    font-family: 'Raleway', sans-serif;
    -webkit-box-shadow: 0px 0px 8px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 8px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 8px -5px rgba(0,0,0,0.75);

	.card-header {
		background-color: rgb(246, 246, 246);
		border-bottom: 2px solid #dbe2e7;
		color: #23282c !important;

		.card-header-title {
			font-weight: 500;
			font-size: 14px;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;

			&.m-title {
				font-weight: 600;
				font-size: 16px;
			}
        }

        button {
            font-weight: 500;
            font-size: 13px;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;

            i {
                font-size: 14px;
                line-height: 14px;
                vertical-align: middle;
            }
        }
	}

	.card-body {
		color: #72777a;
        font-size: 14px;
	}
}


.t-container {
	font-family: 'Raleway', sans-serif;
    background-color: white;

    .sm-hide {
        display: none;
    }
	.sm-hide-inline {
		display: none;
	}
    .sm-show {
        display: flex;
    }

    &:not(.t-no-hover) {
        .row.t-row {
            cursor: pointer;

            &:hover {
                background-color: #e2e2e2;
            }
        }
    }
}

.row {
    .t-cell {
        padding: 0.3rem 0.5rem !important;
    }

    &.t-head {
        font-family: 'Raleway', sans-serif;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        align-items: center;
        justify-content: center;
	}
	
	&.t-head.t-draggable {
		padding-left: 2rem !important;
	}

    &.t-row.t-draggable {
		box-shadow: 0px 0px 5px -1px rgba(102,102,102,1);
		padding-left: 2rem !important;

		.sm-show {
			display: flex;
		}

        .drag-handle {
            display: flex;
            visibility: visible;
            opacity: 1;
            align-items: center;
            justify-content: center;

            i {
                color: gray;
                transform: rotate(90deg);
                font-size: 24px;
            }
        }
    }

    &.t-row {
        font-family: 'Raleway', sans-serif;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        position: relative;

        .drag-handle {
            display: none;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 2rem;
        }

        &:nth-of-type(even) {
            background-color: #f9f9f9;
        }

        button {
            padding: 0 6px;
            flex: 1;

            &.btn-warning {
                color: white;
            }

            i {
                margin: 0 4px;
                line-height: 24px;
                font-size: 14px;
                vertical-align: middle;
            }

            span {
                font-size: 12px;
                font-weight: 500;
                margin-right: 8px;
                line-height: 24px;
                vertical-align: middle;
            }
        }
    }
}

.modal-dialog {
    font-family: 'Raleway', sans-serif;
    width: 90% !important;
    min-width: 90% !important;
	
	.modal-content {
		box-shadow: none;
        border: none;
        
        .card {
            .card-body {
                padding: 0 !important;

                .m-body.padded {
                    padding: 1.5rem !important;
                }

                .m-footer {
                    text-align: right;
                    padding: 1rem !important;

                    a {
                        font-size: 15px;
                        font-weight: 500;
                        cursor: pointer;
                        padding-right: 0.5rem !important;
                        padding-left: 0.5rem !important;
                    }
                }
            }
        }
	}
}

@media only screen and (min-width: 555px) {
    .modal-dialog {
        width: 500px !important;
        min-width: 500px !important;
    }
}

@media only screen and (min-width: 992px) {
    .t-container {
        .sm-hide {
            display: flex;
        }
		.sm-hide-inline {
            display: inline;
        }
        .sm-show {
            display: none;
        }
    }
    .row {

        .t-cell {
            padding: 0.25rem 0.25rem !important;
        }

        &.t-head {
            font-size: 13px;
            font-weight: 500;
            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;
            color: #565a5c !important;

            &.button-pad {
                padding-right: 4rem !important;
            }
        }
        &.t-row {
			&.t-draggable .sm-show {
				display: none;
			}
            
            &.button-pad {
                padding-right: 4rem !important;
            }
            button {
                flex: none;
                padding: 0;

                i {
                    margin: 0 8px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
  .mat-table[matTableResponsive] {
		width: 100%;

    .mat-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;

        & > .mat-sort-header-container {
          padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled {
          display: none;
        }
      }
    }

    .mat-row {
      height: 149px;
			cursor: pointer;
			
			&:nth-of-type(odd) {
				background-color: #f9f9f9;
			}
			
			&:hover {
				background-color: #e2e2e2;
		}

      td.mat-cell {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        word-break: break-word;
		font-size: 13px;
		border-bottom-width: 0px;
		height: 24px;
		font-family: 'Raleway', sans-serif;
		color: #72777a;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
        }

				.badge {
					font-weight: 400;
					width: fit-content;
				}
      }

      &:last-of-type td:last-of-type {
        border: none;
      }
    }
  }

  .mat-card,
  .mat-expansion-panel {
    .mat-table[matTableResponsive] {
      border: 0.5rem solid #fafafa;
    }
  }
}

@media (min-width: 769px) {
	.mat-table[matTableResponsive] {
		width: 100%;

		.mat-header-row {
			height: 36px;

			th.mat-header-cell:first-of-type {
				padding-left: 0;
			}

			th.mat-header-cell:last-of-type {
				padding-right: 10px;
			}

		}
		.mat-row {
			cursor: pointer;
			height: 36px;

			&:nth-of-type(odd) {
				background-color: #f9f9f9;
			}

			&:hover {
				background-color: #e2e2e2;
		}
	
			td.mat-cell {
				font-size: 13px;
				border-bottom-width: 0px;
				font-family: 'Raleway', sans-serif;
				color: #72777a;

				.badge {
					font-weight: 400;
				}

				&:first-of-type {
					padding-left: 0;
				}

				&:last-of-type {
					padding-right: 10px;
				}

			}

		}
	}

	.mat-column-room_id {
		display: flex;
		height: inherit;
		align-items: center;
	}

	.mat-column-index {
		width: 5%;
		text-align: center !important;
	}

	.mat-column-title {
		padding-left: 1% !important;
	}
	.show-more{
		position: relative;
		font-size: 12px;
		font-weight: 600;
		cursor: pointer;
	}
}
.text-bold{
	font-weight: 500;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
